import React from 'react';
import logo from '../logo.svg';
import '../App.css';

function BenefitsPhoto() {

  return (
      <>
          <section className="position-relative overflow-hidden">
              <div className="container pt-5 pb-9 pb-lg-11 z-index-1">
                  <div className="row align-items-center justify-content-between">
                      <div className="order-last col-lg-5">

                          <div className="mb-4 aos-init aos-animate" data-aos="fade-up">
                              <span className="h6 text-muted">Eigenaar aan het woord</span>
                          </div>

                          <h2 className="display-4 position-relative z-index-1 aos-init aos-animate"
                              data-aos="fade-right">Leendert de Borst
                          </h2>
                          <h3 className="display-9">
                              Software Developer / Architect
                          </h3>
                          <span className="width-7x pt-1 bg-gradient-primary d-table mb-6 mt-4 aos-init aos-animate"
                                data-aos="fade-down"></span>

                          <p className="mb-4 aos-init aos-animate" data-aos="fade-up">
                              Ik ben een ondernemer met een passie voor technologie. Mijn kracht en passie ligt in
                              het vertalen van complexe problemen naar simpele en doeltreffende ICT-oplossingen.
                          </p>
                          <p className="mb-7 aos-init aos-animate" data-aos="fade-up">
                              Vanuit XIVISOFT stel ik mijzelf beschikbaar voor consultancy opdrachten en werk ik aan eigen projecten. De speerpunten waar ik met XIVISOFT voor sta:
                          </p>
                          <div className="position-relative z-index-1">
                              <div className="d-flex mb-4 mb-lg-5 aos-init aos-animate" data-aos="fade-up"
                                   data-aos-delay="150">
                                  <div className="me-4">
                                      <i className="bx bx-check-circle text-success lh-1 fs-4"></i>
                                  </div>

                                  <div>
                                      <h5>
                                          De klant centraal
                                      </h5>
                                      <p className="mb-0">
                                          Ik stel het belang van de klant centraal. Ik ben pas tevreden als
                                            de klant tevreden is.
                                      </p>
                                  </div>
                              </div>
                              <div className="d-flex mb-4 mb-lg-5 aos-init aos-animate" data-aos="fade-up"
                                   data-aos-delay="200">
                                  <div className="me-4">
                                      <i className="bx bx-check-circle text-success lh-1 fs-4"></i>
                                  </div>

                                  <div>
                                      <h5>
                                          Eerst het doel, dan de tool
                                      </h5>
                                      <p className="mb-0">
                                          Ik streef er altijd naar de beste technische oplossing te vinden voor het probleem. Daarbij kijk ik niet alleen naar het probleem, maar ook naar welke technologie op dat moment het meest geschikt is voor de business.
                                      </p>
                                  </div>
                              </div>
                              <div className="d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="250">
                                  <div className="me-4">
                                      <i className="bx bx-check-circle text-success lh-1 fs-4"></i>
                                  </div>

                                  <div>
                                      <h5>
                                          (On)gevraagd advies
                                      </h5>
                                      <p className="mb-0">
                                          Ik denk graag belangeloos mee met mijn klanten, ook als dat niet expliciet gevraagd wordt.
                                          Ik ben altijd op zoek naar manieren om mijn klanten te helpen en de
                                          IT-oplossingen die ik lever nog robuuster te maken.
                                      </p>
                                  </div>
                              </div>
                          </div>
                          <div className="mt-7 aos-init aos-animate" data-aos="fade-up" data-aos-delay="250">
                              <a href="https://www.linkedin.com/in/leendert-de-borst-02a60844/" className="btn btn-primary btn-lg rounded-pill hover-lift">
                                  <i className="bx bxl-linkedin fs-5 me-3" style={{marginTop:-4}}></i>
                                  Bekijk LinkedIn profiel
                              </a>
                          </div>

                      </div>
                      <div className="col-lg-5 col-md-10 me-lg-auto order-1 mb-7 mb-lg-0">
                          <div className="position-relative aos-init aos-animate" data-aos="fade-left"
                               data-aos-delay="150">
                              <div
                                  className="bg-primary mt-n9 rounded-block position-absolute start-0 bottom-0 w-100 h-50">
                              </div>
                              <img src="/assets/img/backgrounds/trans_leendert.png" alt=""
                                   className="img-fluid position-relative rounded-4" />
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </>
  );
}

export default BenefitsPhoto;
